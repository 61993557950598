<template>
  <div>
    <div class="container-fluid py-3">
      <div class="container">
        <div class="row">
          <div class="col-lg-9">
            <div
              class="d-flex align-items-center justify-content-between bg-primary py-2 px-4 mb-3"
            >
              <h6 class="m-0 text-white">
                {{ $t("ITEMS_IN_CART") }}
              </h6>
            </div>
            <div class="card mt-3">
              <div
                class="d-flex align-items-center justify-content-between py-2 px-4 pt-2 pb-4"
              >
                <h6 class="m-0">1 ITEMS IN CART</h6>
                <button type="button" class="btn btn-lg btn-danger btn-sm">
                  {{ $t("EMPTY_CART") }}
                </button>
              </div>
              <div class="container mb-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      class="d-flex align-items-center justify-content-between bg-primary py-2 px-4"
                    >
                      <a class="m-0 text-white">
                        Adam Salas (Premium Tipsters)
                      </a>
                      <a href="#" class="text-white">{{ $t("REMOVE_ITEM") }}</a>
                    </div>
                    <div class="card">
                      <table>
                        <tr>
                          <td class="text-center">
                            <div
                              class="d-flex align-items-center justify-content-between"
                            >
                              <div class="m-0 p-0" style="color: #1c50ed">
                                08.10.23 13:30
                              </div>

                              Fixed Odd Full Time
                            </div>
                          </td>
                          <td class="text-center" rowspan="2">
                            <h5>PRICE $350.00</h5>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">
                            <div>
                              <h6>MANCHESTER CITY WOMEN - CHELSEA FC WOMEN</h6>
                              England 1-WSL
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Countrise />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./components/Loading.vue";
import Countrise from "./components/Countrise.vue";

export default {
  components: {
    Loading,
    Countrise,
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
.status {
  background-color: rgb(0, 0, 0);
  border-radius: 4px;
  margin: 2px 0px 0px 0px;
  padding: 0px 6px 3px 6px !important;
  span {
    font-size: 12px;
    color: rgb(255, 255, 255);
  }
}
a {
  font-size: 14px;
}
table {
  padding: 20px;
  font-family: "Kanit", sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}
</style>
